<template>
  <div>
    <el-row :gutter="20" type="flex">
      <el-col :span="24">
        <div class="column">
          <div class="flex-row align-items-center justify-between">
            <div class="flex-row align-items-center">
              <span class="margin-right-10">模型调优</span>
              <el-select size="small" v-model="curProdId" class="margin-right-10">
                <el-option v-for="(item,index) in productList" :key="index" :value="item.productId" :label="item.productName"></el-option>
              </el-select>
              <el-select size="small" v-model="adjustCycle" @change="getData">
                <el-option v-for="(item,index) in adjustCycleList" :key="index" :value="item.value" :label="item.name"></el-option>
              </el-select>
            </div>
          </div>
        </div>
      </el-col>
      <el-col :span="8">
        <div class="column flex-column" style="margin-bottom:0">
          <div>
           <el-table
              v-loading="divisorList.loading"
              :data="divisorList.list"
              :height="$root.docHeight-270"
              size="mini"
              style="width: 100%"
            >
              <div slot="empty"><el-empty :image-size="150"></el-empty></div>
              <el-table-column label="因子">
                <template slot-scope="props">
                  <el-popover
                    placement="right"
                    width="300"
                    trigger="hover"
                  >
                  <div class="font-size-12 line-height-large" style="max-height: 50vh; overflow:auto;">
                    <div v-for="(item,index) in props.row.children" :key="index" :class="{'padding-top-10':index>0,'margin-top-10':index>0,'border-top':index>0}">
                      <div><span class="color-sub">数据名称：</span>{{item.factorName}}</div>
                      <div><span class="color-sub">数据单位：</span>{{item.unit}}</div>
                      <div><span class="color-sub">数据类型：</span>{{item.typeName}}</div>
                      <div><span class="color-sub">更新频率：</span>{{item.modelFreq}}</div>
                      <div><span class="color-sub">统计日期：</span>{{item.startDate}}-{{item.endDate}}</div>
                      <div><span class="color-sub">方法论：</span>{{item.func}}</div>
                      <div><span class="color-sub">数据来源：</span>{{item.dataSource}}</div>
                    </div>
                  </div>
                    <span slot="reference" class="link display-inline-block">
                      {{props.row.source==1?"（上传）":""}}
                      {{props.row.divisorName}}
                      <el-tag v-if="props.row.isTarget==1" type="danger" size="mini" style="transform-origin: (left center);transform:scale(0.9)">目标项</el-tag>
                      <el-tag v-if="props.row.isUsing==0" type="warning" size="mini" style="transform-origin: (left center);transform:scale(0.9)">删除</el-tag>
                    </span>
                  </el-popover>
                </template>
              </el-table-column>
              <el-table-column
                label="筛除"
                align="center"
                width="70"
              >
                <template slot-scope="props"><span v-if="props.row.delRound!=null">第{{props.row.delRound}}轮</span></template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </el-col>
      <el-col :span="16">
        <div class="column flex-column" style="margin-bottom:0">
          <el-row :gutter="20" type="flex" class="flex-auto">
            <el-col :span="8" class="border-right">
              <div>
                <el-table
                  v-loading="roundList.loading"
                  :data="roundList.list"
                  size="mini"
                  @selection-change="roundsSelectionChange"
                  style="width: 100%"
                  :height="$root.docHeight-270"
                  :show-header="false"
                >
                  <div slot="empty"><el-empty :image-size="150"></el-empty></div>
                   <el-table-column type="selection" width="50"></el-table-column>
                  <el-table-column
                    label="模型"
                  >
                    <template slot-scope="props">
                      <el-popover
                        placement="right"
                        width="300"
                        trigger="hover"
                      >
                        <div class="line-height-mid" v-if="props.row.modelType==1">
                          <p>历史估计开始周期：{{props.row.hisCycle}}</p>
                          <p>筛除因子名称：{{props.row.delDivisorName}}</p>
                        </div>
                         <div class="line-height-mid" v-else>
                          <p>历史估计开始周期：{{props.row.hisCycle}}</p>
                        </div>
                        <div slot="reference" class="link display-inline-block">
                          <div>模型：{{props.row.modelName}}<template v-if="props.row.modelType==1">（减一模型）</template></div>
                          <div>时间：{{props.row.addTime}}</div>
                        </div>
                      </el-popover>
                    </template>
                  </el-table-column>
                </el-table>

              </div>
              
            </el-col>
            <el-col :span="16" v-loading="goodResult.loading">
              <Chart :option="modelChart" class="flex-auto"></Chart>
              <div>
                <el-table
                  :data="goodResult.info.dataList"
                  :max-height="$root.docHeight-570"
                  size="mini"
                  style="width: 100%"
                >
                  <div slot="empty"><el-empty :image-size="80"></el-empty></div>
                  <el-table-column label="回测期数" min-width="100" prop="month">
                    <template slot-scope="props"><span class="text-nowarp">{{props.row.month}}</span></template>
                  </el-table-column>
                  <el-table-column
                    v-for="(item) in (goodResult.info.items||[]).filter(v=>{return v.id!='his'})" :key="item.id"
                    :label="item.name"
                  >
                    <template  slot-scope="props"><div v-if="props.row[item.id]"><span class="text-nowarp">{{props.row[item.id].value}}</span></div></template>
                  </el-table-column>
                </el-table>
              </div>
            </el-col>
          </el-row>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import Chart from '../../components/Chart.vue'
export default {
  name: 'modelManageModelTuning',
  components:{
    Chart,
  },
  props:{
    modelFreq:{
      type:String,
      default:'week'
    },
    productList:{
      type:Array,
      default:()=>{
        return []
      }
    }
  },
  data(){
    return {
      curProdId:'',
      adjustCycle:1,
      
      //因子列表
      divisorList:{
        loading:false,
        list:[],
      },
      //对比结果列表
      roundList:{
        loading:false,
        list:[],
        multipleSelection:[],
        active:{}
      },
      //对比结果详情
      goodResult:{
        loading:false,
        info:{},
        chartOpt:this.$defaultChartOption
      },
    }
  },
  computed:{
    //选中的产品
    curProd(){
      if(this.curProdId){
        return this.productList.find(v=>{return v.productId==this.curProdId})
      }else{
        return {}
      }
    },
    //预测步长列表
    adjustCycleList(){
      if(this.modelFreq=="week"){
        return [
          {name:'移动1期预测模型',value:1},
        ]
      }else{
        return [
          {name:'移动1期预测模型',value:1},
          {name:'移动3期预测模型',value:3},
          {name:'移动12期预测模型',value:12}
        ]
      }
    },
    //模型图表
    modelChart(){
      let chartData=this.goodResult.info
      if(chartData.items){
        let opt =JSON.parse(JSON.stringify(this.$defaultChartOption)) 
          opt.legend.data=chartData.items.map(v=>{
            return {
              name:v.name,
              itemStyle:{
                  opacity:0
              }
            }
          })
          opt.xAxis.boundaryGap = false
          opt.xAxis.data=chartData.dataList.filter(v=>{return v.monthShow}).map(v=>{return v.month})
          opt.series=chartData.items.map(v=>{
            return {
              name:v.name,
              data:chartData.dataList.filter(vv=>{return vv.monthShow}).map(vv=>{return vv[v.id]?vv[v.id].calculateValue:""}),
              type: 'line',
              connectNulls:true,
              color:v.id=="his"?"#999":"",
              lineStyle:{
                width:v.id=="his"?4:2,         
                opacity:v.id=="his"?0.5:1,
              }
            }
          })
          return opt
      }else{
        return null
      }
    },
    //重新获取数据监听器
    getDataListener(){
      let modelFreq=this.modelFreq,
          curProdId=this.curProdId
      return{
        modelFreq,
        curProdId
      }
    }

  },
  created(){
    this.curProdId=(this.productList[0]||{}).productId||""
  },
  methods:{
    getData(){
      if(this.curProdId){
        this.getDivisorList()
        this.getRoundList()
      }
    },
    //获取因子列表
    getDivisorList(){
      this.divisorList.loading=true
      this.$instance.get('/dsModel/modelDivisorList',{
          params: {
            ...this.$store.state.basicParams,
            targetId:this.curProd.targetId,
            adjustCycle:this.adjustCycle,
            adjustFrequency:this.modelFreq
          },
      })
      .then(res=>{
        this.divisorList.loading=false
        if(res.data.code==0){
         this.divisorList.list=res.data.info;
        }else{
          this.divisorList.list=[]
        }
      })
      .catch(()=> {
        this.divisorList.loading=false
        this.divisorList.list=[]
      }); 
    },
    //获取模型结果列表
    getRoundList(){
      this.roundList.loading=true
      this.$instance.get('/dsModel/getReduceRound',{
          params: {
            ...this.$store.state.basicParams,
            targetId:this.curProd.targetId,
            adjustCycle:this.adjustCycle,
            adjustFrequency:this.modelFreq
          },
      })
      .then(res=>{
        this.roundList.loading=false
        if(res.data.code==0){
         this.roundList.list=res.data.info;
        }else{
          this.roundList.list=[]
        }
      })
      .catch(()=> {
        this.roundList.loading=false;
        this.roundList.list=[]
      }); 
    },
    //勾选模型结果
    roundsSelectionChange(val) {
      this.roundList.multipleSelection = val;
    },
    //获取因子对比结果
    getGoodResult(){
      this.goodResult.loading=true
      this.$instance.get('/dsModel/getGoodResult',{
          params: {
            ...this.$store.state.basicParams,
            targetId:this.curProd.targetId,
            adjustFrequency:this.modelFreq,
            modelIds:this.roundList.multipleSelection.map(v=>{return v.modelId}).join(',')
          },
      })
      .then(res=>{
        this.goodResult.loading=false
        if(res.data.code==0){
          this.goodResult.info=res.data.info
        }else{
          this.goodResult.info={}
        }
      })
      .catch(()=> {
        this.goodResult.loading=false
        this.goodResult.info={}
      }); 
    },
    
  },
  mounted(){
    

  },
  watch: {
    'productList':{
      handler:function(){
        this.curProdId=(this.productList[0]||{}).productId||""
      },
      deep:true
    },
    'getDataListener':{
      handler: function() {
        this.adjustCycle=1
        this.getData()
      },
      deep:true
    },

    'roundList.multipleSelection':{
      handler: function(val) {
        if(val.length){
          this.getGoodResult()
        }else{
          this.goodResult.info={}
        }
      },
      deep:true
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  
</style>
